header {
    height: 60%;
    width: auto;
    padding-top: 7rem;
  
    overflow: hidden;
}

.header_container {
    text-align:center;
    height: 100%;
    position: relative;
    
}

/* =============== CTA ============= */
.cta {
    margin-top: 6rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* =============== HEADER SOCIALS ============= */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    font-size: 1.2rem;
    top: 7rem;
   
    
    position: absolute;
    
    left: 0;
    
}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
    padding-top: 1rem;
    
}

/* =============== ME ============= */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    filter: opacity(0.8);
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 5rem;
    
    border-radius: 12rem 12rem 12rem 12rem;
    
    overflow: hidden;
    padding: 0rem 0rem 0rem 0rem;

}

/* =============== SCROLL DOWN ============= */
.scroll_down {
    position: absolute;
    
    right: 1rem;
    top: 7rem;
    bottom: 3rem;
    transform: rotate(90deg);
    font-weight: 400;

    font-size: 1.1rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICE) ============== */
@media screen and (max-width: 1024px) {
    header {
        grid-template-columns: 1fr;
        gap: 0;
        height: 50vh;
       
    }

    .me {
        width: 40%;
        
        margin: 2rem auto 4rem;
        margin-left: absolute;
        
        
    }

}

/* =============== MEDIA QUERIES (SMALL DEVICE) ============== */
@media screen and (max-width: 600px) {
    header {
        height: 40vh;
        
    }

    .me {
        /*width: 65%;
        margin: 2rem 1rem 3.3rem 3.3rem; */
        width: 65%;
        max-width: 20rem;
        margin-top: 2.5rem;
        justify-content: center;
      
    }

    .header_socials,
    .scroll_down {
        display: none;
    }

    .cta {
        margin-top: 1.7rem;
        gap: 1rem;
      }
}